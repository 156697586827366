import axios from 'axios';

export default {
  getEntityJournal(pageNumber, pageSize, sortField, sortDesc) {
    return axios.get('/api/EntityJournal', {
      params: {
        pageNumber, pageSize, sortField, sortDesc
      }
    });
  },
};

